.printable {
    display: none
  }
      body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
    }
    table.print-friendly tr td, table.print-friendly tr th {
      page-break-inside: avoid;
    }
    .page {
        width: 210mm;
        min-height: 297mm;
        padding: 20mm;
        margin: 10mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        display: none;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }

    @page {
        size: A4;
        margin: 0;
        margin-top: 20px;
    }
    @media print {
        html, body {
            width: 210mm;
            height: 297mm;
        }
        .printable {
          display: block;
        }
        tr.border_bottom td {
          border-bottom: 0px;
          color: #454545 !important;
        }
        td{
          width: fit-content !important;
        }
        .text_color > *{
          color: #454545 
        }
        .page {
            margin: 0;
            margin-top: 20;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
            display: table;
        }  
        .printable-test-paper-background {
          position: fixed;
          margin-top: 10%;
          font-size: 14px;
          font-family: sans-serif;
        }                 
      }