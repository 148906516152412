.App-header {
  height: 50;
  display: flex;
  color: #FFFFFF;
  align-items: center;
  background-color: #6C5CE7;
  padding: 5px 20px 5px 20px;
  justify-content: space-between;
  width: 100%;
  z-index: 50;
  box-shadow: 2px 2px rgba(53,72,88,.1);
}

*:focus {
  outline: none;
}

::-webkit-scrollbar { 
  display: none; 
} 

.Card-View {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  box-shadow: 1px 2px 6px #8B9DAF26;
  background-color: #FFFFFF;
  border-radius: 4px;
  text-align: center;
}

.Card-View:hover {
  box-shadow: 0px 3px 15px #343B4029 !important;
}

.primary-button {
  color: white;
  padding: 5px 10px;
  background-color: #6C5CE7;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  height: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary-button:hover {
  box-shadow: #5D4FCB80;
  border-color: #5548B9;
  background-color: #5548B9;
}

.Secondary-Button {
  color: #6C5CE7;
  border-color: #6C5CE7;
}

.Secondary-Button:hover {
  background-color: #EEEBFF;
}

.App-Box {
  width: 100%;
  padding: 30px;
  margin-top: 40px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: 4;
}

.App-Test-List {
  width: 100%;
  padding: 30px;
  margin-top: 70px;
}

.App-Box-Title {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 400;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.square {
  height: 30px;
  width: 30px;
  border: 1px solid #40A7FF;
  border-radius: 4px;
}

.Circle {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pagination > li {
  display: inline-block;
  padding-left: 0;
}
.pagination > li {
  list-style: none;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #4B4B4B;
}
.pagination>li.active>a {
  color: #fff;
  background-color: #40A7FF;
  border-radius: 4px;
}
.pagination > li > a:hover {
  background-color:  #52606C;
  color: white;
  border-radius: 4px;
}
@media print {
  .printable-test-paper-background {
    position: fixed;
    margin-top: 10%;
    font-size: 14px;
    font-family: sans-serif;
  }
  .hide-in-print {
    display: none;
    visibility: hidden;
  }
  .printable {
    display: 'block';
  }
}




